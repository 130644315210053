<template>
  <div class="form-container">
    <q-form @submit="onSubmit" class="q-gutter-md">
      <div class="row">
        <div class="col-6">
          <q-input
            square
            outlined
            bottom-slots
            v-model="user.lastName"
            placeholder="Entrez un nom"
            type="text"
            @input.native="e => checkLastName(e)"
            lazy-rules
            :rules="[
              val => (val && val.length > 0) || `Veuillez entrer le nom`
            ]"
          >
            <template v-slot:hint>
              Nom
            </template>
          </q-input>
        </div>
        <div class="col-6">
          <q-input
            square
            outlined
            bottom-slots
            v-model="user.firstName"
            placeholder="Entrez un prénom"
            type="text"
            @input.native="e => checkFirstName(e)"
            lazy-rules
            :rules="[
              val => (val && val.length > 0) || `Veuillez entrer un prénom`
            ]"
          >
            <template v-slot:hint>
              Prénom
            </template>
          </q-input>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <q-input
            square
            outlined
            bottom-slots
            v-model="user.email"
            placeholder="Entrez une adresse email"
            type="email"
            :readonly="readonly"
            lazy-rules
            :rules="[
              val => checkEmail(val) || `Veuillez entrer une adresse email`
            ]"
          >
            <template v-slot:hint>
              Adresse email
            </template>
          </q-input>
        </div>
        <div class="col-6">
          <q-select
            class="department"
            v-model="user.primaryDepartmentId"
            :options="optionsPrimaryDepartmentId"
            hint="Département principal"
            placeholder="Département principal"
            lazy-rules
            :rules="[
              val =>
                checkSelect(val) || `Veuillez choisir un département principal`
            ]"
          />
        </div>
      </div>

      <div class="row">
        <q-select
          filled
          v-model="user.departmentsId"
          use-input
          use-chips
          multiple
          hide-dropdown-icon
          input-debounce="0"
          new-value-mode="add-unique"
          :options="optionsDepartmentsId"
          @filter="filterDepartments"
          hint="Départements secondaire"
          :placeholder="
            user.departmentsId && user.departmentsId.length
              ? ''
              : `Ajoutez des départements`
          "
          class="w-100"
        />
      </div>

      <div class="column">
        <div class="title">
          Droits de l'utilisateur
        </div>
        <div class="toggle-wrapper">
          <q-toggle
            v-model="user.rightProjectDelete"
            label="Droit de supprimer un projet"
            left-label
          />
          <q-toggle
            v-model="user.rightDeleteDocument"
            label="Droit de supprimer un document"
            left-label
          />
          <q-toggle
            v-model="user.rightDeleteModel"
            label="Droit de supprimer un modèle"
            left-label
          />
          <q-toggle
            v-model="user.rightModifyModel"
            label="Droit de modifier un modèle"
            left-label
          />
          <q-toggle
            v-model="user.rightManageUsers"
            label="Droit de gérer les autres utilisateurs (création et modification)"
            left-label
          />
        </div>
      </div>

      <div v-if="!isEdit" class="bottom-buttons">
        <q-btn
          label="Annuler"
          class="btn-cancel"
          type="button"
          @click="$router.go(-1)"
        />
        <q-btn label="Créer l'utilisateur" class="btn-save" type="submit">
          <q-icon class="save-icon" />
        </q-btn>
      </div>
      <div v-if="isEdit" class="bottom-buttons">
        <q-btn
          v-if="user.userStatus === 'ACTIVE'"
          label="Bloquer l'utilisateur"
          class="btn-trash"
          type="button"
          @click="toggleUserStatus"
        >
          <q-icon class="block-icon" />
        </q-btn>

        <q-btn
          v-else
          label="Activate user"
          class="btn-blue"
          type="button"
          @click="toggleUserStatus"
        />

        <q-btn
          label="Annuler"
          class="btn-cancel"
          type="button"
          @click="$router.go(-1)"
        />

        <q-btn label="Sauvegarder" class="btn-save" type="submit">
          <q-icon class="save-icon" />
        </q-btn>
      </div>
    </q-form>

    <BlockUserModal
      :content="contentBlockUser"
      :is-show="isBlocked"
      @closeModal="closeModal"
      @blocked="blocked"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BlockUserModal from '@/components/modals/BlockUser'

export default {
  name: 'UserForm',
  components: { BlockUserModal },
  props: {
    editUser: {
      type: Object,
      default: () => ({})
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      user: {
        userId: this.editUser.userId ?? '',
        firstName: this.editUser.firstName ?? '',
        lastName: this.editUser.lastName ?? '',
        email: this.editUser.email ?? '',
        primaryDepartmentId: this.editUser.primaryDepartmentId ?? [],
        departmentsId: this.editUser.departmentsId ?? [],
        rightDeleteDocument: this.editUser.rightDeleteDocument ?? true,
        rightDeleteModel: this.editUser.rightDeleteModel ?? true,
        rightManageUsers: this.editUser.rightManageUsers ?? true,
        rightModifyModel: this.editUser.rightModifyModel ?? true,
        rightProjectDelete: this.editUser.rightProjectDelete ?? true,
        userStatus: this.editUser.userStatus ?? 'ACTIVE'
      },
      optionsPrimaryDepartmentId: [],
      optionsDepartmentsId: [],
      isBlocked: false,
      contentBlockUser: {
        title: "Bloquer l'utilisateur",
        text: [
          'Voulez-vous bloquer l’utilisateur ?',
          'Il ne pourra plus accéder à Bylaw.'
        ]
      }
    }
  },
  async created() {
    await this.fetchUserDepartments()
    this.optionsPrimaryDepartmentId = this.getUserDepartments
    this.optionsDepartmentsId = this.getUserDepartments
  },
  computed: {
    ...mapGetters('users', [
      'getUserDepartments',
      'getLoadingUserDepartments',
      'getErrorUserDepartments',
      'getErrorSendingUser'
    ])
  },
  methods: {
    ...mapActions('users', [
      'addUser',
      'fetchUserDepartments',
      'putUser',
      'fetchUserById'
    ]),
    async onSubmit({ status = null }) {
      const {
        userId,
        firstName,
        lastName,
        email,
        primaryDepartmentId,
        departmentsId,
        rightDeleteDocument,
        rightDeleteModel,
        rightManageUsers,
        rightModifyModel,
        rightProjectDelete,
        userStatus
      } = this.user

      const data = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email,
        primaryDepartmentId: primaryDepartmentId.departmentId,
        departmentsId: departmentsId.map(department => department.departmentId),
        rightDeleteDocument,
        rightDeleteModel,
        rightManageUsers,
        rightModifyModel,
        rightProjectDelete
      }

      if (!data.departmentsId.length)
        data.departmentsId.push(data.primaryDepartmentId)

      if (this.isEdit) {
        data.userStatus = status ? status : userStatus
        data.userId = userId

        await this.putUser(data)
        this.checkStatusError(true, userId)
      } else {
        await this.addUser(data)
        this.checkStatusError()
      }
    },
    checkLastName() {
      this.user.lastName = this.user.lastName.trimStart()
    },
    checkFirstName() {
      this.user.firstName = this.user.firstName.trimStart()
    },
    async checkStatusError(update = false, userId = null) {
      if (this.getErrorSendingUser.length) return

      // update current user
      if (update) {
        try {
          const currentUser = await this.$Amplify.Auth.currentAuthenticatedUser()

          if (userId === currentUser.attributes.sub) {
            await this.fetchUserById({
              userId: currentUser.username,
              currentUser: true
            })
          }
        } catch (e) {
          this.showNotify('Une erreur est survenue')
          return
        }
      } else {
        this.showNotify(
          "L'utilisateur à été crée avec succès, il va recevoir un email dans un court instant.",
          true
        )
      }

      this.$router.push('/user-management')
    },
    filterDepartments(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase()
        this.optionsDepartmentsId = this.getUserDepartments.filter(
          v => v.value.toLocaleLowerCase().indexOf(needle) > -1
        )
      })
    },
    setModel(val) {
      this.model = val
    },
    toggleUserStatus() {
      if (this.user.userStatus === 'ACTIVE') {
        this.isBlocked = true
      } else {
        this.onSubmit({ status: 'ACTIVE' })
      }
    },
    closeModal() {
      this.isBlocked = false
    },
    blocked() {
      this.isBlocked = false
      this.onSubmit({ status: 'BLOCKED' })
    },
    checkEmail(val) {
      if (val && val.length && !this.validateEmail(val))
        return 'Incorrect email'
      if (val && val.length > 0) return true
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    checkSelect(val) {
      if (Object.keys(val).length) return true
    },
    showNotify(message, empty = false) {
      const {
        color,
        icon,
        timeout,
        position,
        colorEmpty,
        iconEmpty
      } = this.$q.config.alert

      const _color = !empty ? color : colorEmpty
      const _icon = !empty ? icon : iconEmpty

      this.$q.notify({ color: _color, icon: _icon, message, position, timeout })
    }
  },
  watch: {
    editUser: function(user) {
      this.user = user
    },
    getErrorSendingUser: function(message) {
      if (message && message.length) this.showNotify(message)
    }
  }
}
</script>
