<template>
  <div class="new-user">
    <q-page-container>
      <q-page>
        <UserForm />
      </q-page>
    </q-page-container>
  </div>
</template>

<script>
import UserForm from '@/components/new-user/UserForm'

export default {
  name: 'NewUser',
  components: {
    UserForm
  }
}
</script>
